function createSuklWidgetConfig() {
  var codes = [];
  var medicalProdIds = [];
  var vetProdIds = [];
  var certificates = [{
    image: {
      url: "/images/footer/sukl-vet-pl.png",
      alt: "G\u0142\xF3wny Inspektorat Weterynarii",
      width: 120,
      height: 100
    },
    link: "https://pasze.wetgiw.gov.pl/otc/demo/index.php?jezyk=1"
  }];
  return {
    suklWidget: {
      codes: codes,
      medicalProdIds: medicalProdIds,
      vetProdIds: vetProdIds,
      certificates: certificates
    }
  };
}
export default defineAppConfig(createSuklWidgetConfig());