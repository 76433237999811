export default {
  inputTypes: {
    zipCode: 'text',
    crn: 'text',
    vatId: 'text',
  },
  inputModes: {
    zipCode: 'numeric',
    crn: 'text',
    vatId: 'numeric',
  },
}
